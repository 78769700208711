import { Button } from "@/components/button/Button";
import { LicenseAgreement } from "@/components/licenseAgreement/LicenseAgreement";
import { Step } from "@/components/step/Step";
import { labels } from "@/utils/labels";
import styles from "./ThirdStep.module.scss";
import { ButtonType } from "@/types/types";
import { IconNames } from "@/types/iconNames";
import { useDispatch, useSelector } from "react-redux";
import {
  getAcceptLicense,
  getCompleteSteps,
  getEmail,
  getLastTraceId,
  getWaitingStatus,
} from "@/utils/selectors";
import { useEffect, useState } from "react";
import { toggleDownload } from "@/features/download/downloadSlice";
import { LoginDialog } from "@/components/loginDialog/LoginDialog";
import { addTraceId } from "@/features/traceId/traceIdSlice";
import { toggleCompleteSteps } from "@/features/step/stepSlice";
import { setCookie } from "cookies-next";
import { resetAllError } from "@/features/error/errorSlice";

export type ThirdStepProps = {
  isActive: boolean;
};

export const ThirdStep = ({ isActive }: ThirdStepProps) => {
  const dispatch = useDispatch();
  const acceptLicense = useSelector(getAcceptLicense);
  const userEmail = useSelector(getEmail);
  const waitingStatus = useSelector(getWaitingStatus);
  const [openLoginDialog, setOpenLoginDialog] = useState<boolean>(false);

  const lastTraceId = useSelector(getLastTraceId);
  const completeSteps = useSelector(getCompleteSteps);

  /**
   * @description When user come back from login page, the sdkGeneration can start thanks to the value change
   * of downloadActive state - manage from toggleDownload action
   */
  useEffect(() => {
    if (userEmail && acceptLicense && completeSteps) {
      dispatch(toggleDownload(true));
    }
  }, [userEmail, acceptLicense, completeSteps]);

  /**
   * Handle click on I Accept button.
   *
   * @description when user click on this button we set some global states:
   * aceptLicense become true
   * add the lastTraceId gnerated in FirstStep, inside downloadQuery state
   * if the user is not yet logged in, oblige him to do
   */
  const handleClick = () => {
    dispatch(addTraceId(lastTraceId));
    dispatch(toggleCompleteSteps(true));
    setCookie("userCompletedSteps", true);
    dispatch(resetAllError());

    if (userEmail && acceptLicense && completeSteps) {
      dispatch(toggleDownload(true));
    }

    if (!userEmail) {
      setOpenLoginDialog(true);
    }
  };

  return (
    <>
      <Step
        index={3}
        title={labels.STEP3_TITLE}
        isActive={isActive}
        hasDownloadPdfBtn
      >
        {isActive && !acceptLicense && <LicenseAgreement />}
      </Step>
      {((isActive && !acceptLicense) || (!isActive && acceptLicense)) && (
        <span className={styles.btnContainer}>
          <Button
            buttonText={labels.BUILD_YOUR_SDK}
            iconName={IconNames.TOOLS}
            iconColor="var(--color-primary)"
            iconWidth={20}
            iconHeight={20}
            type={ButtonType.PRIMARY}
            disabled={!acceptLicense}
            onClick={() => handleClick()}
            customClassName={styles.button}
            ariaLabel="Build your SDK action"
          />
        </span>
      )}
      <LoginDialog
        open={openLoginDialog}
        onOpenChange={() => setOpenLoginDialog(false)}
        hasCancelBtn
        closeBtnAction={() => setOpenLoginDialog(false)}
      />
    </>
  );
};
