import styles from "./LicenseAgreementButtons.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { IconNames } from "@/types/iconNames";
import { downloadFile, downloadLicenseCube2 } from "@/utils/function";
import { labels } from "@/utils/labels";
import { Button } from "@/components/button/Button";
import {
  setAcceptLicense,
  setIsInDownload,
} from "@/features/license/licenseSlice";
import { ButtonType } from "@/types/types";
import classNames from "classnames";
import { LicenseDialog } from "../licenseDialog/LicenseDialog";
import {
  getActiveTab,
  getIsInDownload,
  getSelectedRow,
  getSelectedSoftwareCube2,
} from "@/utils/selectors";
import { baseUrl } from "@/utils/constant";

export type LicenseButtonsProps = {
  isDisabled?: boolean;
};

export const LicenseAgreementButtons = ({
  isDisabled,
}: LicenseButtonsProps) => {
  const dispatch = useDispatch();
  const [openLicenseDialog, setOpenLicenseDialog] = useState<boolean>(false);
  const activeTab = useSelector(getActiveTab);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const selectedRow = useSelector(getSelectedRow);
  const isLicenseInDownload = useSelector(getIsInDownload);

  const listFeatures = selectedSoftwareCube2.map((item) => item.featureId);

  return (
    <>
      <section className={styles.container}>
        <Button
          customClassName={styles.button}
          buttonText={labels.DOWNLOAD_PDF}
          iconName={IconNames.DOWNLOAD_FILE}
          iconColor="var(--color-secondary)"
          iconHeight={16}
          iconWidth={16}
          type={ButtonType.LINK}
          onClick={() => {
            dispatch(setIsInDownload(true));
            if (activeTab === 1) {
              downloadFile(
                `${baseUrl}/licensepdf/1`,
                "SDK_software_license_agreement.pdf",
                () => dispatch(setIsInDownload(false))
              );
            } else {
              downloadLicenseCube2(
                `${baseUrl}/pdfcube2`,
                listFeatures,
                selectedRow?.seriesId!,
                "SDK_software_license_agreement_cube_2.pdf",
                () => dispatch(setIsInDownload(false))
              );
            }
          }}
          ariaLabel="Download PDF action"
          isLoading={isLicenseInDownload}
        />
        <div className={styles.acceptBtnBox}>
          <button
            type="button"
            className={classNames(styles.button, styles.greyBtn)}
            onClick={() => {
              setOpenLicenseDialog(true);
            }}
            aria-label="No accept action"
          >
            {labels.NO_ACCEPT}
          </button>
          <button
            type="button"
            className={classNames(styles.button, styles.greyBtn, {
              [styles.disabled]: isDisabled,
            })}
            onClick={() => dispatch(setAcceptLicense(true))}
            disabled={isDisabled}
            aria-label="Accept action"
          >
            {labels.ACCEPT}
          </button>
        </div>
      </section>

      <LicenseDialog
        open={openLicenseDialog}
        onOpenChange={() => setOpenLicenseDialog(false)}
        hasCloseBtn
        onSetOpenDialog={setOpenLicenseDialog}
        title={labels.LICENCE_REFUSAL}
        closeBtnAction={() => setOpenLicenseDialog(false)}
      />
    </>
  );
};
