import { useGetLicensesQuery } from "@/pages/api/apiSlice";
import styles from "./LicenseAgreement.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getSelectedRow,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
} from "@/utils/selectors";
import DOMPurify from "dompurify";
import { labels } from "@/utils/labels";
import { SidebarItem } from "./components/sidebarItem/SidebarItem";
import { LicenseAgreementButtons } from "./components/licenseAgreementButtons/LicenseAgreementButtons";
import { Skeleton } from "../skeleton/Skeleton";

export const LicenseAgreement = () => {
  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const selectedRow = useSelector(getSelectedRow);
  const licenseBoxRef = useRef<HTMLDivElement>(null);
  const [visibleSectionIds, setVisibleSectionIds] = useState<string[]>(["0"]);

  const packList = selectedSoftwareCube1.map((item) => item.softwareId);
  const featureList = selectedSoftwareCube2.map((item) => item.featureId);

  const {
    data: licensesData,
    isFetching,
    isLoading,
  } = useGetLicensesQuery({
    cube: selectedSoftwareCube2.length > 0 ? 2 : 1,
    listPacks: packList || [],
    listFeatures: featureList || [],
    series: selectedRow?.seriesId!,
  });

  const composedLicensesData = useMemo(() => {
    if (licensesData) {
      return licensesData.licenses.map((license, index) => ({
        id: index,
        licenseText: license,
      }));
    }
  }, [licensesData]);

  /**
   * Handle scroll. Check if the top of the section corresponding to elementId
   * have a lower value than the bottom of container section (licenseBoxRef.current)
   *
   * @param elementId
   */
  const isSectionVisible = (elementId: string) => {
    const section = document.getElementById(elementId);

    if (section && licenseBoxRef.current) {
      const sectionPosition = section.getBoundingClientRect();
      const containerPosition = licenseBoxRef.current.getBoundingClientRect();

      return sectionPosition.top <= containerPosition.bottom;
    }
    return false;
  };

  /**
   * Handle scroll. Check which section is visible
   */
  const handleScroll = () => {
    const visibleSectionIdsArray: string[] = [];
    if (composedLicensesData) {
      composedLicensesData.forEach(({ id }) => {
        const stringId = id.toString();
        if (isSectionVisible(stringId)) {
          visibleSectionIdsArray.push(stringId);
        }
      });
    }
    setVisibleSectionIds(visibleSectionIdsArray);
  };

  useEffect(() => {
    const licenseBox = licenseBoxRef.current;

    if (licensesData && licenseBox) {
      licenseBox.addEventListener("scroll", handleScroll);
    }
    // Pulizia dell'evento al dismount del componente
    return () => licenseBox?.removeEventListener("scroll", handleScroll);
  }, [licenseBoxRef.current, licensesData]);

  if (isFetching || isLoading) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton width={"100%"} height={"2.5rem"} />
        <Skeleton width={"100%"} height={"60.8rem"} />
        <div className={styles.skeletonBtns}>
          <Skeleton width={"12.3rem"} height={"4rem"} />
          <Skeleton width={"21.1rem"} height={"4rem"} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.title}>{labels.LICENSES_PARAGRAPH}</p>
      <>
        <div className={styles.licenseContainer}>
          <section className={styles.sidebar}>
            {licensesData &&
              licensesData.licenses.map((item, index) => {
                return (
                  <SidebarItem
                    key={item + "_" + index}
                    text={"License " + (index + 1)}
                    isHighlighted={visibleSectionIds.includes(index.toString())}
                  />
                );
              })}
          </section>
          <section className={styles.licenseBox} ref={licenseBoxRef}>
            {licensesData?.licenses.map((license, index) => {
              const licenseWithoutStars = license[0].replace(/\*/g, "");
              const sanitizedLicenseData =
                DOMPurify.sanitize(licenseWithoutStars);

              return (
                <p
                  id={index.toString()}
                  className={styles.licenseText}
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: sanitizedLicenseData as string,
                  }}
                ></p>
              );
            })}
          </section>
        </div>

        <LicenseAgreementButtons
          isDisabled={
            visibleSectionIds.length !== licensesData?.licenses.length
          }
        />
      </>
    </div>
  );
};
