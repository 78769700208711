import styles from "./Step.module.scss";
import classnames from "classnames";
import { Search } from "@/components/search/Search";
import { labels } from "@/utils/labels";
import { Button } from "../button/Button";
import { IconNames } from "@/types/iconNames";
import { ButtonType } from "@/types/types";
import { downloadFile, downloadLicenseCube2 } from "@/utils/function";
import { useDispatch, useSelector } from "react-redux";
import {
  getAcceptLicense,
  getActiveTab,
  getIsInDownload,
  getSelectedRow,
  getSelectedSoftwareCube2,
} from "@/utils/selectors";
import { setIsInDownload } from "@/features/license/licenseSlice";
import { baseUrl } from "@/utils/constant";

export type StepProps = {
  title: string;
  index: number;
  hasSearch?: boolean;
  children?: React.ReactNode;
  isActive?: boolean;
  hasTitleMarginBottom?: boolean;
  packCounter?: number | undefined;
  hasDownloadPdfBtn?: boolean;
};

export const Step = ({
  title,
  index,
  hasSearch,
  children,
  isActive,
  hasTitleMarginBottom,
  packCounter,
  hasDownloadPdfBtn,
}: StepProps) => {
  const acceptLicense = useSelector(getAcceptLicense);
  const activeTab = useSelector(getActiveTab);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const selectedRow = useSelector(getSelectedRow);
  const isLicenseInDownload = useSelector(getIsInDownload);

  const dispatch = useDispatch();

  const listFeatures = selectedSoftwareCube2.map((item) => item.featureId);

  return (
    <section
      className={classnames(styles.container, { [styles.active]: isActive })}
    >
      <div
        className={classnames(styles.titleContainer, {
          [styles.marginBottom]: hasTitleMarginBottom,
        })}
      >
        <div className={styles.titleBox}>
          <span
            className={classnames(styles.index, { [styles.active]: isActive })}
          >
            {index}
          </span>
          <h2
            className={classnames(styles.title, { [styles.active]: isActive })}
          >
            {title}
          </h2>
        </div>
        {/* counter */}
        {!isActive && !!packCounter && packCounter !== 0 && (
          <p className={styles.counter}>{`${packCounter} ${
            packCounter > 1 ? `${labels.PACKS}` : `${labels.PACK}`
          } selected`}</p>
        )}
        {/* search */}
        {hasSearch && <Search containerClassname={styles.search} />}
        {/* download PDF */}
        {!isActive && hasDownloadPdfBtn && acceptLicense && (
          <span className={styles.btnContainer}>
            <Button
              buttonText={labels.DOWNLOAD_PDF}
              iconName={IconNames.DOWNLOAD}
              iconColor="var(--color-secondary)"
              type={ButtonType.LINK}
              onClick={() => {
                dispatch(setIsInDownload(true));
                if (activeTab === 1) {
                  downloadFile(
                    `${baseUrl}/licensepdf/1`,
                    "SDK_software_license_agreement.pdf",
                    () => dispatch(setIsInDownload(false))
                  );
                } else {
                  downloadLicenseCube2(
                    `${baseUrl}/pdfcube2`,
                    listFeatures,
                    selectedRow?.seriesId!,
                    "SDK_software_license_agreement_cube_2.pdf",
                    dispatch
                  );
                }
              }}
              ariaLabel="Download PDF action"
              isLoading={isLicenseInDownload}
            />
          </span>
        )}
      </div>
      {children && <>{children}</>}
    </section>
  );
};
